<template>
  <div class="test1">
    <el-header class="header">
      <edit-header
        :title="title"
        :description="description"
        v-on:titleChanged="changeTitle($event)"
        v-on:descriptionChanged="changeDescription($event)"
        v-on:industryChanged="changeIndustry($event)"
        v-on:companyChanged="changeCompany($event)"
        v-on:currencyChanged="changeCurrency($event)"
        v-on:publishClicked="publish($event)"
        v-on:saveClicked="save($event)"
        v-on:onConfirm="dialogCancel($event)"
      >
      </edit-header>
    </el-header>

    <el-container class="container">
      <el-aside class="side">
        <el-tabs v-model="activeName">
          <el-tab-pane label="Type" name="first">
            <div class="edit">
              <div class="ques">
                <div class="edit-title">Question Type</div>
                <div class="edit-ques">
                  <i class="el-icon-check"></i>
                  <span>
                    Single Choice&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'single_choice';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-finished"></i>
                  <span> Multiple Choice&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'multiple_choice';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    String&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'string';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Float&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'float';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Email&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'email';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Date&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'date';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span> Multiple Object&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'multiple_object';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Address&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'address';
                      willAddQuestion.variable_name = 'address';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    SIC Code&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'sic_code';
                      willAddQuestion.variable_name = 'sic_code';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-aside>

      <el-main class="main">
        <div class="ques">
          <div class="title">Questionnaire Title: {{ title }}</div>
          <div class="industry">Industry: {{ industry }}</div>
          <div class="company">Company Type: {{ company }}</div>
          <div class="currency">Currency: {{ currency }}</div>
          <div class="description">Description: {{ description }}</div>

          <div class="content">
            <el-row
              class="ques-block"
              v-for="item in questions"
              :key="item.num"
              @mouseover.native="hoverItem = item.id"
            >
              <el-col :span="16" class="block-content">
                <div class="block-title">
                  {{ item.id }}. {{ item.question_en }}
                  <span class="must" v-if="item.must">(Required)</span>
                  <span class="must" v-if="item.hide">(Hidden)</span>
                </div>
                <div class="block-description">
                  Question Name: {{ item.name }}
                </div>
                <div class="block-description">
                  Variable Name: {{ item.variable_name }}
                </div>
                <div class="block-description">
                  Question Type: {{ item.type }}
                </div>
                <div
                  class="block-choice"
                  v-for="ans in item.options"
                  :key="ans.id"
                >
                  <el-radio v-if="item.type === 'single_choice'" value="0">
                    {{ ans.title }}
                  </el-radio>
                  <el-checkbox v-if="item.type === 'multiple_choice'" value="0">
                    {{ ans.title }}
                  </el-checkbox>
                  <el-input
                    v-if="
                      item.type === 'string' ||
                        item.type === 'float' ||
                        item.type === 'email' ||
                        item.type === 'address' ||
                        item.type === 'date'
                    "
                    type="textarea"
                    placeholder=""
                    v-bind="ans.title"
                  >
                  </el-input>
                </div>
                <div
                  class="block-relation"
                  v-if="isLogic && item.last_question !== 0"
                >
                  <div>{{ relatedQs(item) }}</div>
                </div>
              </el-col>
              <el-col
                :span="8"
                class="block-button"
                style="text-align: right"
                v-if="hoverItem === item.id"
              >
                <el-button-group>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Edit"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-edit"
                      @click="edit(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    v-if="isLogic"
                    effect="light"
                    content="Add relation"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-paperclip"
                      @click="logic(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Copy"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-document-copy"
                      @click="copy(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Delete"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-delete"
                      @click="del_pre(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Move up"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-caret-top"
                      @click="up(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Move down"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-caret-bottom"
                      @click="down(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Move to"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-sort"
                      @click="moveToBtn(item.id)"
                    ></el-button>
                  </el-tooltip>
                </el-button-group>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
    </el-container>

    <el-dialog
      :title="qsEditDialogTitle"
      :visible.sync="qsEditDialogVisible"
      :before-close="cancel_pre"
      class="dialog"
    >
      <el-form ref="form" :model="willAddQuestion" label-width="100px">
        <el-form-item label="Question Type" label-width="120px">
          <el-select
            v-model="willAddQuestion.type"
            placeholder="Select Question Type"
            @change="typeChange"
          >
            <el-option
              v-for="item in allType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="not-info">
          <el-form-item
            label="Variable Name"
            label-width="120px"
            style="width: 90%"
          >
            <el-input
              v-model="willAddQuestion.variable_name"
              placeholder="variable name"
              :disabled="
                willAddQuestion.type == 'address' ||
                  willAddQuestion.type == 'sic_code'
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Question Name"
            label-width="120px"
            style="width: 90%"
          >
            <el-input
              v-model="willAddQuestion.name"
              placeholder="Name"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Question_en"
            label-width="120px"
            style="width: 90%"
          >
            <el-input
              v-model="willAddQuestion.question_en"
              placeholder="question_en"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="Question_zh"
            label-width="120px"
            style="width: 90%"
          >
            <el-input
              v-model="willAddQuestion.question_zh"
              placeholder="question_zh"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="Description_en"
            label-width="120px"
            style="width: 90%"
          >
            <el-input
              v-model="willAddQuestion.description_en"
              placeholder="description_en"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="Description_zh"
            label-width="120px"
            style="width: 90%"
          >
            <el-input
              v-model="willAddQuestion.description_zh"
              placeholder="description_zh"
            ></el-input>
          </el-form-item>

          <el-form-item label="Pie_chart_area" label-width="120px">
            <el-select
              v-model="willAddQuestion.fk_pie_chart_sector_id"
              placeholder="chooose fk_pie_chart_sector_id"
              @change="fk_pie_chart_sector_idChange"
            >
              <el-option
                v-for="item in allPieChartArea"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Export_type" label-width="120px">
            <el-select
              v-model="willAddQuestion.export_type"
              placeholder="choose export_type"
              @change="export_typeChange"
            >
              <el-option
                v-for="item in allExportType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Is Required">
            <el-checkbox v-model="willAddQuestion.must">Required</el-checkbox>
          </el-form-item>

          <el-form-item label="Is Hidden">
            <el-checkbox v-model="willAddQuestion.hide">Hidden</el-checkbox>
          </el-form-item>

          <template
            v-if="
              willAddQuestion.type === 'single_choice' ||
                willAddQuestion.type === 'multiple_choice' ||
                willAddQuestion.type === 'multiple_object'
            "
          >
            <el-form-item
              :label="'choices' + (index + 1)"
              v-for="(item, index) in willAddQuestion.options"
              :key="index"
            >
              <el-row v-if="willAddQuestion.export_type != 'customize'">
                <el-col :span="16">
                  <el-input
                    v-model="item.title"
                    placeholder="Option Name"
                  ></el-input>
                </el-col>

                <el-col :span="16">
                  <el-input
                    v-model="item.title_zh"
                    placeholder="选项的中文"
                  ></el-input>
                </el-col>

                <el-col :span="8">
                  <el-button
                    type="danger"
                    plain
                    class="deleteOptionButton"
                    @click="deleteOption(index)"
                    >Delete Option</el-button
                  >
                </el-col>
              </el-row>
              <el-row v-else>
                <el-col :span="6">
                  <el-input
                    v-model="item.title"
                    placeholder="Option Name"
                  ></el-input>
                </el-col>
                <el-col :span="4">
                  <p style="line-height: 13px; margin-left: 10px">
                    Export Value:
                  </p>
                </el-col>
                <el-col :span="6">
                  <el-input
                    v-model="item.value"
                    placeholder="Export Value"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="danger"
                    plain
                    class="deleteOptionButton"
                    @click="deleteOption(index)"
                    >Delete Option</el-button
                  >
                </el-col>
                <el-col :span="16">
                  <el-input
                    v-model="item.title_zh"
                    placeholder="选项的中文"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-button
              type="primary"
              plain
              class="addOptionButton"
              @click="addOption"
              >New Option</el-button
            >
          </template>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-row>
          <el-button :span="6" type="primary" @click="dialogConfirm"
            >Confirm</el-button
          >
          &emsp;
          <el-button :span="6" @click="cancel_pre">Cancel</el-button>
        </el-row>
      </span>
    </el-dialog>

    <!--    关联问题弹框-->
    <el-dialog
      :title="qsLogicDialogTitle"
      :visible.sync="qsLogicDialogVisible"
      :before-close="cancel_pre_logic"
      class="dialog"
    >
      <el-form
        v-if="questions.length !== 0"
        class="logic-form"
        ref="form"
        label-width="80px"
      >
        <div class="logic-title">
          Current Question: {{ questions[logicIndex].id }}.
          {{ questions[logicIndex].question_en }}
        </div>

        <el-form-item label="Related Question" label-width="130px">
          <el-select
            v-if="questionsFilter.length > 0"
            v-model="willAddLogic.question_id"
            placeholder="Please select related question"
          >
            <el-option
              v-for="item in questionsFilter"
              :key="item.id"
              :label="idTitle(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <p v-else style="line-height: 13px; margin-left: 50px">
            No question with choice before this question.
          </p>
        </el-form-item>

        <el-form-item
          label="Related Option"
          v-if="willAddLogic.question_id !== 0"
          label-width="130px"
        >
          <el-select
            v-model="willAddLogic.option_id"
            placeholder="Please select related option"
          >
            <el-option
              v-for="item in questions[willAddLogic.question_id - 1].options"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div class="logic-info" v-if="willAddLogic.question_id !== 0">
          Only when question<span style="color: #1687fd">
            "{{ idTitle(questions[willAddLogic.question_id - 1]) }}" </span
          >'s answer is<span style="color: #1687fd">
            "{{
              questions[willAddLogic.question_id - 1].options[
                willAddLogic.option_id - 1
              ].title
            }}" </span
          >, current question will be asked
        </div>
        <div class="logic-info" v-if="questionsFilter.length === 0">
          There is no single or multiple choice before this question, cannot set
          related question.
        </div>

        <div class="logic-bt">
          <el-button type="primary" @click="logicConfirm(logicIndex)"
            >Confirm</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- Move to弹框 -->
    <el-dialog
      :title="moveDialogTitle"
      :visible.sync="moveDialogVisible"
      class="dialog"
    >
      <el-form
        :rules="rules"
        :model="ruleForm"
        ref="ruleForm"
        @submit.native.prevent
      >
        <div>
          <span>Current: {{ this.curPostion }}</span> <br />
          <span>Max: {{ this.maxPostion }}</span
          ><br />
          <span
            >PS:此功能不能移动子问题，如需移动子问题请使用上/下按钮，切记保持父子文字整体连在一起</span
          >
        </div>
        <el-form-item label="Where move to" prop="moveToNum">
          <el-input
            v-model.number="ruleForm.moveToNum"
            autocomplete="off"
            @keyup.enter.native="submitForm('ruleForm')"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="submitForm('ruleForm')" type="primary"
            >MOVE</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import editHeader from "@/components/header/editHeader";
import getDataApi from "@/utils/getDataApi";
import saveDataApi from "@/utils/saveDataApi";

export default {
  name: "LeftScreen",
  mixins: [getDataApi, saveDataApi],

  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("cant be ept"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("number plz"));
        } else {
          if (value === this.curPostion) {
            callback(new Error("can't be current Position"));
          } else if (value < 1 || value > this.maxPostion) {
            callback(new Error("unvalid"));
          } else {
            callback();
          }
        }
      }, 200);
    };
    return {
      rules: {
        moveToNum: [{ validator: checkNum, trigger: "blur" }],
      },
      template: "",
      curPostion: 0,
      maxPostion: 0,
      // movoTo_Positon: 0,
      ruleForm: {
        moveToNum: "",
      },
      logicQuestionState: 0, // 用于判断用户是否更改关联问题设置
      logicOptionState: 0, // 用于判断用户是否更改关联选项设置
      linkShare: "",
      isLogic: true, // 问卷是否引入关联逻辑
      editIndex: 0,
      logicIndex: 0,
      selectDisAble: false,
      hoverItem: 0,
      activeName: "first",
      title: "Please add questionnaire name",
      description: "",
      industry: "Please add questionnaire industry ID",
      company: "Please add company type ID",
      currency: "Please add questionnaire currency",
      type: "",
      questions: [],
      // outline: [],
      qid: this.$route.query.qid,
      qsEditDialogVisible: false,
      qsEditDialogTitle: "New Question",
      qsLogicDialogVisible: false,
      qsLogicDialogTitle: "Relation Settings",
      moveDialogVisible: false,
      moveDialogTitle: "Move to the specified position",
      willAddQuestion: {
        question_id: 0,
        id: 0,
        type: "",
        variable_name: "",
        name: "",
        question_en: "",
        question_zh: "",
        description_en: "",
        description_zh: "",
        fk_company_type_id: "0",
        export_type: "string",
        must: false,
        hide: false,
        options: [
          {
            title: "",
            title_zh: "",
            value: "",
            id: 1,
          },
        ],
        last_question: 0,
        last_option: 0,
      },
      willAddLogic: {
        question_id: 0,
        option_id: 0,
      },
      allIndustry: [],
      allCompanyType: [],
      allType: [
        {
          value: "single_choice",
          label: "single_choice",
        },
        {
          value: "multiple_choice",
          label: "multiple_choice",
        },
        {
          value: "string",
          label: "string",
        },
        {
          value: "float",
          label: "float",
        },
        {
          value: "email",
          label: "email",
        },
        {
          value: "multiple_object",
          label: "multiple_object",
        },
        {
          value: "address",
          label: "address",
        },
        {
          value: "sic_code",
          label: "sic_code",
        },
        {
          value: "date",
          label: "date",
        },
      ],
      allExportType: [
        {
          value: "string",
          label: "string",
        },
        {
          value: "index_0",
          label: "index_0",
        },
        {
          value: "index_1",
          label: "index_1",
        },
        {
          value: "1/0",
          label: "1/0",
        },
        {
          value: "customize",
          label: "customize",
        },
      ],
      allPieChartArea: [],
    };
  },
  components: {
    editHeader,
  },
  methods: {
    moveToBtn: function(index) {
      this.curPostion = index;
      this.maxPostion = this.questions.length;
      this.moveDialogVisible = true;
    },
    submitForm(formName) {
      console.log(this.ruleForm.moveToNum);
      console.log(this.questions);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.moveTo();
          this.moveDialogVisible = false;
          // setTimeout(() => {
          //   this.$message({
          //     message: "move successfully",
          //     type: "success",
          //   });
          // }, 500);

          this.$refs[formName].resetFields();
          // alert("submit!");
        } else {
          this.$message.error("MOVE Failed");
          console.log("error !!!!!");
          return false;
        }
      });
    },
    moveTo: function() {
      console.log(`___________________________`);
      function getSonQuestionIndexs(pos, questions) {
        let returnArr = [];
        const fatherIds = [pos + 1];

        for (let i = pos + 1; ; i++) {
          if (
            questions[i] &&
            fatherIds.indexOf(questions[i].last_question) !== -1
          ) {
            returnArr.push(i);
            fatherIds.push(questions[i].id);
          } else {
            break;
          }
        }
        return returnArr;
      }

      console.log(this.curPostion, this.ruleForm.moveToNum);
      const start = this.curPostion - 1;
      const target = this.ruleForm.moveToNum - 1;
      if (this.questions[start].last_question) {
        this.$message.error("Cannot move child question!");
        return;
      } else {
        const sons = getSonQuestionIndexs(start, this.questions);

        console.log("sons: ", [start, ...sons]);

        if (
          this.questions[target + 1] &&
          this.questions[target + 1].last_question
        ) {
          this.$message.error("Target invalid!");
          return;
        } else {
          let questionsTemp = JSON.parse(JSON.stringify(this.questions));

          const moveLength = [start, ...sons].length;
          const movedElem = questionsTemp.splice(start, moveLength);
          if (start < target) {
            questionsTemp.splice(target - moveLength + 1, 0, ...movedElem);
          } else {
            questionsTemp.splice(target, 0, ...movedElem);
          }
          this.$message({
            message: "move successfully",
            type: "success",
          });

          let relationship = {};
          for (let j = 0; j < questionsTemp.length; j++) {
            relationship[questionsTemp[j].id] = j + 1;

            questionsTemp[j].id = j + 1;

            // if is son question
            if (questionsTemp[j].last_question) {
              const previousFatherId = questionsTemp[j].last_question;
              const newFatherId = relationship[previousFatherId];
              questionsTemp[j].last_question = newFatherId;
            }
          }
          this.questions = questionsTemp;
        }
      }
      //  test git
      /**
             let num = this.moveToNum;
            console.log(`curPostion: ` + this.curPostion);
            console.log(`num: ` + num);
            if (num < this.curPostion) {
                //从后往前move
                //非法移动判断
                if (
                this.questions[this.curPostion - 1].last_question !== 0 &&
                num <= this.questions[this.curPostion - 1].last_question
                ) {
                this.$message.error(
                    "Cannot move relate question (child) above related question (parent)!"
                );
                return;
                }
                // 挪动位置
                this.questions.splice(
                num - 1,
                0,
                this.questions.splice(this.curPostion - 1, 1)[0]
                );
                console.log(`从后往前移动位置后`);
                //题序维护+关联维护
                //假如移动的这个question是个父问题就单独维护它
                let arr = this.sonGroup(num - 1);
                if (arr.length !== 0) {
                //把它所有的子问题的last_questiond都改成这个
                for (let j = 0; j < arr.length; j++) {
                    // console.log(arr[j].last_question);
                    // console.log(num);
                    arr[j].last_question = num;
                    console.log(arr[j]);
                    console.log(arr[j].last_question);
                }
                }
                // 假如移动的这个不是父问题，开始维护区间里面的
                this.questions[num - 1].id = num;
                for (let i = this.curPostion; i > num; i--) {
                console.log(`i:` + i);
                let arr = this.sonGroup(i);
                if (arr.length !== 0) {
                    for (let j = 0; j < arr.length; j++) {
                    arr[j].last_question++;
                    console.log(`excute`);
                    }
                }

                this.questions[i - 1].id++;
                console.log(this.questions[i]);
                console.log(`^^^^^`);
                }
            } else if (num == this.curPostion) {
                //原地移动
                this.$message.error("You are moving to current position");
                return;
            } else {
                //从前往后move
                //非法移动判断
                let sonGroup = this.sonGroup(this.curPostion - 1);
                if (sonGroup.length !== 0 && num >= sonGroup[0].id) {
                this.$message.error(
                    "Cannot move relate question (child) above related question (parent)!"
                );
                return;
                }
                // 挪动位置
                this.questions.splice(
                num - 1,
                0,
                this.questions.splice(this.curPostion - 1, 1)[0]
                );
                console.log(`从前往后移动位置后`);
                //题序维护 + 关联维护
                //假如移动的这个question是个父问题就单独维护它
                let arr = this.sonGroup(num - 1);
                if (arr.length !== 0) {
                for (let j = 0; j < arr.length; j++) {
                    arr[j].last_question = num;
                    console.log(`num:` + num);
                    console.log(arr[j].last_question);
                    console.warn(
                    "==========",
                    arr[j],
                    arr[j].last_question,
                    arr[j],
                    arr[j].last_question,
                    arr[j]
                    );
                }
                }
                this.questions[num - 1].id = num;
                for (let i = this.curPostion - 1; i < num - 1; i++) {
                let arr = this.sonGroup(i);
                if (arr.length !== 0) {
                    for (let j = 0; j < arr.length; j++) {
                    arr[j].last_question--;
                    }
                }
                this.questions[i].id--;
                }
            }
        **/
    },
    edit: function(index) {
      index--;
      this.willAddQuestion = {
        variable_name: this.questions[index].variable_name,
        export_type: this.questions[index].export_type,
        hide: this.questions[index].hide,
        question_id: this.questions[index].question_id,
        id: this.questions[index].id,
        name: this.questions[index].name,
        type: this.questions[index].type,
        question_en: this.questions[index].question_en,
        question_zh: this.questions[index].question_zh,
        must: this.questions[index].must,
        fk_pie_chart_sector_id:
          this.questions[index].fk_pie_chart_sector_id + ``,
        description_en: this.questions[index].description_en,
        description_zh: this.questions[index].description_zh,
        options: JSON.parse(JSON.stringify(this.questions[index].options)),
        last_question: this.questions[index].last_question,
        last_option: this.questions[index].last_option,
      };
      this.editIndex = index;
      this.selectDisAble = true;
      this.qsEditDialogTitle = "Edit Question";
      this.qsEditDialogVisible = true;
    },
    isExistEmptyOption: function() {
      for (let i = 0; i < this.willAddQuestion.options.length; i++) {
        if (this.willAddQuestion.options[i].title === "") return true;
        if (
          this.willAddQuestion.export_type == "customize" &&
          this.willAddQuestion.options[i].value === ""
        )
          return true;
      }
      return false;
    },
    isExistSameOption: function() {
      for (let i = 0; i < this.willAddQuestion.options.length; i++) {
        for (let j = 0; j < this.willAddQuestion.options.length; j++) {
          if (
            i !== j &&
            this.willAddQuestion.options[i].title ===
              this.willAddQuestion.options[j].title
          )
            return true;
        }
      }
      return false;
    },
    isValidVariableName: function(variableName) {
      return /^[a-zA-Z_]\w*$/.test(variableName);
    },
    dialogConfirm() {
      let index = this.editIndex;
      // this.qsEditDialogVisible = false;
      if (this.qsEditDialogTitle === "Edit Question") {
        // this.updateOutline(this.willAddQuestion.id, this.willAddQuestion.question_en);
        if (!this.isValidVariableName(this.willAddQuestion.name)) {
          this.$message({
            type: "error",
            message: "Name is not in a valid format!",
          });
          return;
        }
        if (!this.isValidVariableName(this.willAddQuestion.variable_name)) {
          this.$message({
            type: "error",
            message: "Varibale name is not in a valid format!",
          });
          return;
        }
        // if (this.willAddQuestion.name === '') {
        //     this.$message({
        //         type: 'error',
        //         message: 'Name cannot be empty!'
        //     });
        //     return;
        // }
        // if (this.willAddQuestion.variable_name === '') {
        //     this.$message({
        //         type: 'error',
        //         message: 'Variable name cannot be empty!'
        //     });
        //     return;
        // }
        if (this.willAddQuestion.question_en === "") {
          this.$message({
            type: "error",
            message: "Question_en cannot be empty!",
          });
          return;
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistEmptyOption()
        ) {
          this.$message({
            type: "error",
            message: "Option cannot be empty!",
          });
          return;
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistSameOption()
        ) {
          this.$message({
            type: "error",
            message: "Duplicated Option Name!",
          });
          return;
        } else {
          this.questions[index].question_id = this.willAddQuestion.id;
          this.questions[index].id = this.willAddQuestion.id;
          this.questions[index].type = this.willAddQuestion.type;
          this.questions[index].name = this.willAddQuestion.name;
          this.questions[
            index
          ].variable_name = this.willAddQuestion.variable_name;
          this.questions[index].question_en = this.willAddQuestion.question_en;
          this.questions[index].question_zh = this.willAddQuestion.question_zh;
          this.questions[
            index
          ].description_en = this.willAddQuestion.description_en;
          this.questions[
            index
          ].description_zh = this.willAddQuestion.description_zh;
          this.questions[
            index
          ].fk_pie_chart_sector_id = this.willAddQuestion.fk_pie_chart_sector_id;
          this.questions[index].export_type = this.willAddQuestion.export_type;
          this.questions[index].must = this.willAddQuestion.must;
          this.questions[index].hide = this.willAddQuestion.hide;
          this.questions[index].options = JSON.parse(
            JSON.stringify(this.willAddQuestion.options)
          );
          this.questions[
            index
          ].last_question = this.willAddQuestion.last_question;
          this.questions[index].last_option = this.willAddQuestion.last_option;
          // 大纲更新
          // this.updateOutline(this.willAddQuestion.id, this.willAddQuestion.question_en);
          this.qsEditDialogTitle = "";
          this.qsEditDialogVisible = false;
          this.$message({
            type: "success",
            message: "Successful!",
          });
          console.log(this.questions);
          this.resetWillAdd();
          this.selectDisAble = false;
        }
        this.resetWillAdd();
        this.selectDisAble = false;
      } else {
        if (!this.isValidVariableName(this.willAddQuestion.name)) {
          this.$message({
            type: "error",
            message: "Name is not in a valid format!",
          });
          return;
        }
        if (!this.isValidVariableName(this.willAddQuestion.variable_name)) {
          this.$message({
            type: "error",
            message: "Varibale name is not in a valid format!",
          });
          return;
        }
        // if (this.willAddQuestion.name === '') {
        //     this.$message({
        //         type: 'error',
        //         message: 'Name cannot be empty!'
        //     });
        //     return;
        // }
        // if (this.willAddQuestion.variable_name === '') {
        //     this.$message({
        //         type: 'error',
        //         message: 'Variable name cannot be empty!'
        //     });
        //     return;
        // }
        if (this.willAddQuestion.question_en === "") {
          this.$message({
            type: "error",
            message: "Question_en cannot be empty!",
          });
          return;
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistEmptyOption()
        ) {
          this.$message({
            type: "error",
            message: "Option cannot be empty!",
          });
          return;
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistSameOption()
        ) {
          this.$message({
            type: "error",
            message: "Duplicated option!",
          });
          return;
        } else {
          this.qsEditDialogVisible = false;
          this.willAddQuestion.id = this.questions.length + 1;
          // 大纲更新
          // this.updateOutline(this.willAddQuestion.id, this.willAddQuestion.question_en);
          // if (this.willAddQuestion.type==='judge') {
          //   this.willAddQuestion.options = [{title: '对', id: 1}, {title: '错', id: 2}];
          // }
          this.questions.push(this.willAddQuestion);
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          // 重置
          this.resetWillAdd();
        }
      }
    },
    resetWillAdd() {
      this.willAddQuestion = {
        question_id: 0,
        id: 0,
        type: "",
        variable_name: "",
        name: "",
        question_en: "",
        question_zh: "",
        description_en: "",
        description_zh: "",
        fk_pie_chart_sector_id: "0",
        export_type: "string",
        must: false,
        hide: false,
        options: [
          {
            title: "",
            title_zh: "",
            value: "",
            id: 1,
          },
        ],
        last_question: 0,
        last_option: 0,
      };
    },
    dialogCancel: function() {
      this.qsEditDialogTitle = "";
      this.resetWillAdd();
      this.qsEditDialogVisible = false;
      this.selectDisAble = false;
    },
    cancel_pre: function() {
      this.$confirm(
        "Info you have input will not be saved, are you sure you want to close this window?",
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.dialogCancel();
        })
        .catch(() => {});
    },
    LogicDialogCancel: function() {
      this.qsLogicDialogVisible = false;
      this.resetLogic();
    },
    cancel_pre_logic: function() {
      this.$confirm(
        "Info you have input will not be saved, are you sure you want to close this window?",
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.LogicDialogCancel();
        })
        .catch(() => {});
    },
    logicConfirm: function(index) {
      console.log(this.questions);

      if (this.questions[index].last_question == 0) {
        // 当这个要加关联的问题是单独的问题时，实现先跳转到该父问题的后面去再加关联
        this.curPostion = index + 1;
        this.ruleForm.moveToNum =
          this.willAddLogic.question_id +
          this.sonGroup(this.willAddLogic.question_id - 1).length +
          1;
        this.moveTo();
        index = this.ruleForm.moveToNum - 1;

        let questions = this.questions;
        let willAddLogic = this.willAddLogic;
        questions[index].last_question = willAddLogic.question_id;
        questions[index].last_option = willAddLogic.option_id;
        // questions[index].hide = willAddLogic.question_id !== 0;
        if (
          this.willAddLogic.question_id !== this.logicQuestionState ||
          this.willAddLogic.option_id !== this.logicOptionState
        ) {
          this.$message({
            type: "success",
            message: `Successfully set relation. Question has been moved to ${this.ruleForm.moveToNum}. The father question is ${willAddLogic.question_id}`,
          });
        }
        this.qsLogicDialogVisible = false;
        this.resetLogic();
        this.ruleForm.moveToNum = "";
      } else {
        // 当该问题已经有关联了，现在相当于时改关联，那么现在直接改关联，不做跳转
        console.log(
          `该问题已经是子问题了，完成关联修改，但是不移动位置。请维护好整个问卷的，父子问题的连在一起的状态！`
        );
        let questions = this.questions;
        let willAddLogic = this.willAddLogic;
        questions[index].last_question = willAddLogic.question_id;
        questions[index].last_option = willAddLogic.option_id;
        // questions[index].hide = willAddLogic.question_id !== 0;
        if (
          this.willAddLogic.question_id !== this.logicQuestionState ||
          this.willAddLogic.option_id !== this.logicOptionState
        ) {
          this.$message({
            type: "success",
            message: `Successfully set relation. The father question is ${willAddLogic.question_id}`,
          });
        }
        this.qsLogicDialogVisible = false;
        this.resetLogic();
        this.ruleForm.moveToNum = "";
      }
    },
    logic: function(index) {
      this.logicIndex = index - 1;
      this.willAddLogic.question_id = this.questions[index - 1].last_question;
      if (this.questions[index - 1].last_option !== 0) {
        this.willAddLogic.option_id = this.questions[index - 1].last_option;
      } else this.willAddLogic.option_id = 1;
      console.log(this.questions);
      this.qsLogicDialogVisible = true;
      this.logicQuestionState = this.willAddLogic.question_id;
      this.logicOptionState = this.willAddLogic.option_id;
    },
    resetLogic() {
      this.willAddLogic = {
        question_id: 0,
        option_id: 1,
      };
      this.logicIndex = 0;
    },
    typeChange(value) {
      this.willAddQuestion.type = value;
    },
    export_typeChange(value) {
      this.willAddQuestion.export_type = value;
    },
    fk_pie_chart_sector_idChange(value) {
      this.willAddQuestion.fk_pie_chart_sector_id = value;
    },
    addOption() {
      // 问题关联检查
      // if (this.willAddQuestion.id !== 0 && this.sonGroup(this.willAddQuestion.id-1).length !== 0) {
      //   this.$message({
      //     type: 'warning',
      //     message: '此问题已被关联，无法新增选项，请删除关联信息后再试'
      //   });
      //   return;
      // }
      this.willAddQuestion.options.push({
        title: "",
        id: this.willAddQuestion.options.length + 1,
      });
    },
    deleteOption(index) {
      if (this.willAddQuestion.options.length === 1) {
        this.$message({
          type: "error",
          message: "At least one option!",
        });
      } else {
        // 问题关联检查
        if (
          this.willAddQuestion.id !== 0 &&
          this.sonGroup(this.willAddQuestion.id - 1).length !== 0
        ) {
          this.$message({
            type: "warning",
            message:
              "There is a question related to this question and options in this question cannot be deleted, please cancel the relation and then try deleting option again.",
          });
          return;
        }
        // id重排
        for (let i = index + 1; i < this.willAddQuestion.options.length; i++) {
          this.willAddQuestion.options[i].id--;
        }
        this.willAddQuestion.options.splice(index, 1);
      }
    },
    changeTitle: function(value) {
      this.title = value;
    },
    changeDescription: function(value) {
      this.description = value;
    },
    changeIndustry: function(value) {
      this.industry = value;
    },
    changeCompany: function(value) {
      this.company = value;
    },
    changeCurrency: function(value) {
      this.currency = value;
    },
    save() {
      if (this.title === "Please add questionnaire name") {
        this.$message.error("Please set a questionnaire name");
        return;
      }
      if (this.industry === "Please add questionnaire industry ID") {
        this.$message.error("Please set questionnaire industry ID");
        return;
      }
      if (this.company === "Please add company type ID") {
        this.$message.error("Please set questionnaire company type ID");
        return;
      }
      if (this.currency === "Please add questionnaire currency") {
        this.$message.error("Please set questionnaire currency");
        return;
      }
      //this.questions是问卷结构的 下面初始化的questionnaire是数据库需要的结构，所以我们现在就是在把问卷结构的内容填进questionnaire里
      var questionnaire = {};
      // 问卷的问题得有不同的分区，目前只设了这一个company_information分区。如果后面要加的话，首先要在新建的问题的界面就加上一个属性叫问题的分区，让consultant选择。
      questionnaire.company_information = {};
      // template是老东西 不用管
      if (this.template != "") {
        questionnaire.template = this.template;
      }
      console.log(this.questions);
      for (let i = 0; i < this.questions.length; i++) {
        questionnaire.company_information[this.questions[i].id] = {
          name: this.questions[i].name,
          type: this.questions[i].type,
          fk_pie_chart_sector_id: this.questions[i].fk_pie_chart_sector_id + "",
          export_type: this.questions[i].export_type,
          question_en: this.questions[i].question_en,
          question_zh: this.questions[i].question_zh,
          description_en: this.questions[i].description_en,
          description_zh: this.questions[i].description_zh,
          choices: null,
          choices_zh: null,
          answers: [],
          is_required: this.questions[i].must,
          is_subquestion: this.questions[i].last_question !== 0,
          is_hidden: this.questions[i].hide,
          variable_name: this.questions[i].variable_name,
        };

        if (this.questions[i].options.length > 0) {
          // 这里就是把每个有选项的问题，它对应的选项的值先赋值成null，后面检测到子问题后再改成空数组，然后再push子问题的题号！
          questionnaire.company_information[this.questions[i].id][
            "choices"
          ] = {};
          // 这是初始化choices_zh这个对象
          questionnaire.company_information[this.questions[i].id][
            "choices_zh"
          ] = {};
          for (let j = 0; j < this.questions[i].options.length; j++) {
            questionnaire.company_information[this.questions[i].id]["choices"][
              this.questions[i].options[j].title
            ] = null;

            // 我们只要设置choices_zh的key就行. 如果consultant没填写 或者只写了空格.那么我们就把选项的id也就是optId(从1开始)+未定义传入,然后把有中文的就保存成key就行.value就是null.
            if (
              this.questions[i].options[j].title_zh == null ||
              this.questions[i].options[j].title_zh.trim() === ""
            ) {
              let optId = j + 1;
              questionnaire.company_information[this.questions[i].id][
                "choices_zh"
              ][optId + ":未定义"] = null;
            } else {
              questionnaire.company_information[this.questions[i].id][
                "choices_zh"
              ][this.questions[i].options[j].title_zh] = null;
            }
            //   console.log(this.questions[i].options[j].title_zh);
            // console.log(typeof this.questions[i].options[j].title_zh);
          }
        }
        //最恐怖的地方
        // 下面的这个if就是把问卷格式的父问题 改成数据库格式的父问题。
        if (this.questions[i].last_question != 0) {
          // 这里要做的事，检测到该问题事子问题，那么就需要往父问题的选项里push该子问题的题号，但是由于它的父问题在初始化时选项的值是null，所以没法push，所以下面这个if就是把这个null改成了空数组，这样以便于我们push
          if (
            // questionnaire里的company_information的每个问题的 choices是一个对象，如果这个问题是选择题的话，每一个选项就是choices对象的key，value就是该选项对应的所有子问题组成的数组，里面存的是从1开始的题号！
            questionnaire.company_information[this.questions[i].last_question]
              .choices[
              this.questions[this.questions[i].last_question - 1].options[
                this.questions[i].last_option - 1
              ].title
            ] == null
          ) {
            questionnaire.company_information[
              this.questions[i].last_question
            ].choices[
              this.questions[this.questions[i].last_question - 1].options[
                this.questions[i].last_option - 1
              ].title
            ] = [];
          }
          // 检测到当前问题有父问题，就往数据库结构里的 父问题的选项这个对象，的相应的key里（value是一个数组）push当前问题的题号
          questionnaire.company_information[
            this.questions[i].last_question
          ].choices[
            this.questions[this.questions[i].last_question - 1].options[
              this.questions[i].last_option - 1
            ].title
          ].push(this.questions[i].id);
        }

        // 如果是customize类型的题，且,就往当前问题的custom对象的customize_values对象里 初始化：custom对象的customize_values对象，key就选项名，v键值就是选项对象的value
        if (this.questions[i].export_type == "customize") {
          questionnaire.company_information[this.questions[i].id][
            "custom"
          ] = {};
          questionnaire.company_information[this.questions[i].id][
            "custom_zh"
          ] = {};
          questionnaire.company_information[this.questions[i].id]["custom"][
            "customize_values"
          ] = {};
          questionnaire.company_information[this.questions[i].id]["custom_zh"][
            "customize_values"
          ] = {};
          for (let j = 0; j < this.questions[i].options.length; j++) {
            questionnaire.company_information[this.questions[i].id]["custom"][
              "customize_values"
            ][this.questions[i].options[j].title] = this.questions[i].options[
              j
            ].value;
            questionnaire.company_information[this.questions[i].id][
              "custom_zh"
            ]["customize_values"][
              this.questions[i].options[j].title_zh
            ] = this.questions[i].options[j].value;
          }
        }
      }
      console.log(questionnaire);
      // 下面就是根据qid来设置localStorage。如果没有qid
      if (this.qid != undefined) {
        window.localStorage.setItem(
          `questionnaire_name${this.qid}`,
          this.title
        );
        window.localStorage.setItem(
          `questionnaire_industry${this.qid}`,
          this.industry
        );
        window.localStorage.setItem(
          `questionnaire_company${this.qid}`,
          this.company
        );
        window.localStorage.setItem(
          `questionnaire_description${this.qid}`,
          this.description
        );
        window.localStorage.setItem(
          `questionnaire_currency${this.qid}`,
          this.currency
        );
        window.localStorage.setItem(
          `questionnaire${this.qid}`,
          JSON.stringify(this.questions)
        );
        window.localStorage.setItem(
          `question_json${this.qid}`,
          JSON.stringify(questionnaire)
        );
      } else {
        window.localStorage.setItem("questionnaire_name", this.title);
        window.localStorage.setItem("questionnaire_industry", this.industry);
        window.localStorage.setItem("questionnaire_company", this.company);
        window.localStorage.setItem(
          "questionnaire_description",
          this.description
        );
        window.localStorage.setItem("questionnaire_currency", this.currency);
        window.localStorage.setItem(
          "questionnaire",
          JSON.stringify(this.questions)
        );
        window.localStorage.setItem(
          "question_json",
          JSON.stringify(questionnaire)
        );
      }
      this.$message.success("Questionnaire Saved!");
    },
    up: function(index) {
      index--;
      let questions = this.questions;
      if (index !== 0) {
        // 非法移动
        if (questions[index].last_question === index) {
          this.$message.error(
            "Cannot move relate question (child) above related question (parent)!"
          );
          return;
        }
        // 关联维护
        let arr = this.sonGroup(index);
        let willSubtract = [];
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            willSubtract.push(arr[j].id - 1);
          }
        }
        arr = this.sonGroup(index - 1);
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            questions[arr[j].id - 1].last_question++;
          }
        }
        for (let i = 0; i < willSubtract.length; i++) {
          questions[willSubtract[i]].last_question--;
        }
        // 题序维护
        questions[index].id--;
        questions[index - 1].id++;
        let temp = questions[index];
        questions[index] = questions[index - 1];
        questions[index - 1] = temp;
        // 大纲维护
        // this.updateOutline(index, questions[index-1].question_en);
        // this.updateOutline(index+1, questions[index].question_en);
      }
    },
    down: function(index) {
      index--;
      let questions = this.questions;
      if (index !== questions.length - 1) {
        // 非法移动
        if (questions[index + 1].last_question === index + 1) {
          this.$message.error(
            "Cannot move relate question (child) above related question (parent)!"
          );
          return;
        }
        // 关联维护
        let arr = this.sonGroup(index);
        let willAdd = [];
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            willAdd.push(arr[j].id - 1);
          }
        }
        arr = this.sonGroup(index + 1);
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            questions[arr[j].id - 1].last_question--;
          }
        }
        for (let i = 0; i < willAdd.length; i++) {
          questions[willAdd[i]].last_question++;
        }
        // 题序维护
        questions[index].id++;
        questions[index + 1].id--;
        let temp = questions[index];
        questions[index] = questions[index + 1];
        questions[index + 1] = temp;
        // 大纲维护
        // this.updateOutline(index+1, questions[index].question_en);
        // this.updateOutline(index+2, questions[index+1].question_en);
      }
    },
    del_pre: function(index) {
      // 问题关联检查
      if (this.sonGroup(index - 1).length !== 0) {
        this.$confirm(
          "This question has been related, delete this question will also delete the relation.",
          "提示",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.del_relation(index);
            this.del(index);
            this.$message({
              type: "success",
              message: "Question and relation has been deleted",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Cancelled",
            });
          });
      } else {
        this.$confirm(
          "Are you sure you want to cancel this? (Cannot recover)",
          "提示",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.del(index);
            this.$message({
              type: "success",
              message: "Delete successfuly",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Cancel",
            });
          });
      }
    },
    del: function(index) {
      index--;
      let questions = this.questions;
      // let outline = this.outline;
      // 维护关联关系
      for (let i = index + 1; i < questions.length; i++) {
        let arr = this.sonGroup(i);
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            if (questions[arr[j].id - 1] !== index) {
              questions[arr[j].id - 1].last_question--;
            }
          }
        }
      }
      // 维护题序
      for (let i = index + 1; i < questions.length; i++) {
        questions[i].id--;
      }
      // outline.splice(index,1);
      questions.splice(index, 1);
      // 维护大纲
      // for (let num=index; num<outline.length; num++) {
      //     this.updateOutline(num+1, questions[num].question_en);
      // }
    },
    copy: function(index) {
      index--;
      let questions = this.questions;
      // 大纲更新
      // this.updateOutline(this.outline.length + 1, questions[index].question_en);
      // 问卷更新
      let temp = this.deepClone(questions[index]);
      temp.id = questions.length + 1;
      temp.question_id = 0;
      questions.push(temp);
      this.$message.success(
        "A new copy of this question has been attached to the end of the questionnaire."
      );
    },
    deepClone: function(initialObj) {
      let obj = {};
      try {
        obj = JSON.parse(JSON.stringify(initialObj));
        // eslint-disable-next-line no-empty
      } finally {
      }
      return obj;
    },

    updateQuestions: function(start, end) {
      let offset = end - start;
      if (offset > 0) {
        for (let i = 0; i < offset; i++) {
          this.down(start + i);
        }
      } else {
        for (let i = 0; i < offset * -1; i++) {
          this.up(start - i);
        }
      }
    },

    handleDrop(draggingNode, dropNode, dropType) {
      console.log("tree drop: ", draggingNode.key, dropNode.key, dropType);
      if (this.isRightRelation(draggingNode.key, dropNode.key)) {
        this.updateQuestions(draggingNode.key, dropNode.key);
      } else {
        // this.outline.splice(0, this.outline.length);
        // this.InitOutline();
        this.$message.error(
          "Cannot move relate question (child) above related question (parent)!"
        );
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.key > dropNode.key) {
        return type === "prev";
      } else {
        return type === "next";
      }
    },
    isRightRelation: function(index1, index2) {
      index1--;
      index2--;
      let questions = this.questions;
      if (index1 < index2) {
        for (let i = index1 + 1; i <= index2; i++) {
          if (questions[i].last_question === index1 + 1) {
            return false;
          }
        }
        return true;
      } else {
        for (let i = index1 - 1; i >= index2; i--) {
          if (questions[index1].last_question === i + 1) {
            return false;
          }
        }
        return true;
      }
    },
    idTitle: function(item) {
      return item.id + ". " + item.question_en;
    },
    relatedQs: function(item) {
      let fatherIndex;

      for (const i of this.questions) {
        if (i.id === item.last_question) {
          fatherIndex = this.questions.indexOf(i);
          break;
        }
      }
      // console.log(fatherIndex,this.questions[fatherIndex],item.last_option ,this.questions[fatherIndex].options[item.last_option - 1])
      // return ("Relate to question：" ,this.questions[fatherIndex].options[item.last_option - 1].title)

      //
      // console.log(this.questions[item.last_question - 1])
      // console.log(item);

      try {
        return (
          "Relate to question：" +
          this.questions[fatherIndex].id +
          "." +
          this.questions[fatherIndex].question_en +
          "(" +
          this.questions[fatherIndex].options[item.last_option - 1].title +
          ")"
        );
      } catch (e) {
        console.error(item, this.questions[fatherIndex]);
        return "Error: Cannot found related question";
      }
    },
    // 遍历得爸爸
    fatherIndex: function(qs_id) {
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].question_id === qs_id) {
          return i;
        }
      }
    },
    // 遍历得儿子
    sonGroup: function(index) {
      let arr = [];
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].last_question === this.questions[index].id) {
          arr.push(this.questions[i]);
        }
      }
      return arr;
    },
    // 连带删除关联信息
    del_relation: function(index) {
      for (let i = 0; i < this.questions.length; i++) {
        // 保存加入后,下面需修改id为question_id
        if (this.questions[i].last_question === this.questions[index - 1].id) {
          this.questions[i].last_option = 0;
          this.questions[i].last_question = 0;
          this.questions[i].is_shown = true;
        }
      }
    },
    // 关联信息存在性判断
    isExistRelation: function() {
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].last_question !== 0) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    questionsFilter: function() {
      let questions = this.questions;
      let arr = [];
      for (let i = 0; i < this.logicIndex; i++) {
        if (
          questions[i].type === "single_choice" ||
          questions[i].type === "multiple_choice" ||
          questions[i].type === "multiple_object"
        ) {
          arr.push(questions[i]);
        }
      }
      return arr;
    },
  },
  created() {
    // 获取饼图分区
    this.$axios({
      method: "get",
      url: `https://api.oxvalue.cn/api/plugin/v0.1/pie_chart_sector/`,
      headers: {
        Authorization: window.localStorage.getItem("ova_admin_token"),
      },
    }).then((res) => {
      console.log(`piechart api: ${res}`);
      const sector_list = res.data.data.sector_list;
      this.allPieChartArea = [{ id: "0", name: "None" }];
      sector_list.forEach((item) => {
        this.allPieChartArea.push({
          id: item.id,
          name: item.name,
        });
      });
    });
    // this.getQnDataSelf();
    if (this.qid != undefined) {
      if (
        window.localStorage.getItem(`questionnaire${this.qid}`) != undefined
      ) {
        this.title = window.localStorage.getItem(
          `questionnaire_name${this.qid}`
        );
        this.industry = window.localStorage.getItem(
          `questionnaire_industry${this.qid}`
        );
        this.company = window.localStorage.getItem(
          `questionnaire_company${this.qid}`
        );
        this.currency = window.localStorage.getItem(
          `questionnaire_currency${this.qid}`
        );
        this.description = window.localStorage.getItem(
          `questionnaire_description${this.qid}`
        );
        this.questions = JSON.parse(
          window.localStorage.getItem(`questionnaire${this.qid}`)
        );
      } else {
        this.$axios({
          method: "get",
          url:
            "https://api.oxvalue.cn/api/question/v0.1/questionnaire_admin/" +
            this.qid +
            "/",
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
        }).then((res) => {
          console.log(res);
          this.title = res.data.data.name;
          this.description = res.data.data.description;
          this.industry = res.data.data.fk_industry_id;
          this.company = res.data.data.fk_company_type_id;
          this.currency = res.data.data.currency;
          this.questions = [];
          console.log(JSON.parse(res.data.data.questions));
          // this.outline = [];
          var questions = JSON.parse(res.data.data.questions);
          for (var type in questions) {
            if (type == "template") {
              this.template = questions["template"];
              continue;
            }
            for (var q in questions[type]) {
              var current_question = questions[type][q];
              var question = {
                question_id: parseInt(q),
                name: current_question["name"],
                id: parseInt(q),
                variable_name: current_question["variable_name"],
                type: current_question["type"],
                export_type: current_question["export_type"],
                question_en: current_question["question_en"],
                question_zh: current_question["question_zh"],
                must: current_question["is_required"],
                hide: current_question["is_hidden"],
                last_question: 0,
                last_option: 0,
                fk_pie_chart_sector_id:
                  current_question["fk_pie_chart_sector_id"],
                description_en: current_question["description_en"],
                description_zh: current_question["description_zh"],
                options: [],
              };
              // read options
              if (
                current_question["type"] == "single_choice" ||
                current_question["type"] == "multiple_choice" ||
                current_question["type"] == "multiple_object"
              ) {
                let i = 1;
                Object.keys(current_question["choices"]).forEach((key) => {
                  question.options.push({
                    title: key,
                    value: "",
                    id: i,
                  });
                  i++;
                });
                // 遍历opiton数组，数组里存的是一个个对象，往每个对象加一个键值对，key是title_zh，value是从current_question里获取到中文

                // console.log(`!!!!!!!!!!!!!!`);
                // console.log(current_question);
                let j = 0;
                Object.keys(current_question["choices_zh"]).forEach((key) => {
                  question.options[j]["title_zh"] = key;
                  j++;
                });

                // read customize option values
                if (current_question["export_type"] == "customize") {
                  for (let i = 0; i < question.options.length; i++) {
                    question.options[i].value =
                      current_question["custom"]["customize_values"][
                        question.options[i].title
                      ];
                  }
                }
              }
              this.questions.push(question);
            }
          }
          // read relation
          for (let type in questions) {
            if (type == "template") continue;
            for (let q in questions[type]) {
              let current_question = questions[type][q];
              if (
                current_question["type"] == "single_choice" ||
                current_question["type"] == "multiple_choice" ||
                current_question["type"] == "multiple_object"
              ) {
                let option_index = 1;
                Object.keys(current_question["choices"]).forEach((key) => {
                  if (current_question["choices"][key] != null) {
                    for (
                      let j = 0;
                      j < current_question["choices"][key].length;
                      j++
                    ) {
                      this.questions[
                        current_question["choices"][key][j] - 1
                      ].last_question = parseInt(q);
                      this.questions[
                        current_question["choices"][key][j] - 1
                      ].last_option = option_index;
                    }
                  }
                  option_index++;
                });
              }
            }
          }
        });
      }
    } else {
      if (window.localStorage.getItem("questionnaire") != undefined) {
        this.title = window.localStorage.getItem("questionnaire_name");
        this.industry = window.localStorage.getItem("questionnaire_industry");
        this.company = window.localStorage.getItem("questionnaire_company");
        this.currency = window.localStorage.getItem("questionnaire_currency");
        this.description = window.localStorage.getItem(
          "questionnaire_description"
        );
        this.questions = JSON.parse(
          window.localStorage.getItem("questionnaire")
        );
      }
    }
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style>
.test1 .linkDialog {
  text-align: left;
}
.test1 .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test1 header {
  padding: 0 10px;
}

.test1 .el-container {
  padding: 0 5px;
}

.test1 .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test1 .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test1 .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test1 .outline {
  overflow: auto;
  height: 550px;
}

.test1 .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test1 .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test1 .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test1 .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test1 .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test1 .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test1 .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test1 .el-tabs__header {
  margin: 0;
}

.test1 .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

.test1 .main {
  /* max-height: 610px; */
}

.test1 .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test1 .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test1 .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test1 .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test1 .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test1 .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test1 .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test1 .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test1 .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test1 .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test1 .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test1 .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test1 .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test1 .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test1 .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test1 .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test1 .dialog {
  text-align: left;
  font-size: large;
}
.test1 .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test1 .deleteOptionButton {
  margin-left: 20px;
}

.test1 .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test1 .important {
  color: crimson;
}

.test1 .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test1 .logic-bt {
  text-align: center;
}

.test1 .addOptionButton {
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test1 .deleteOptionButton {
  margin-left: 20px;
}

.test1 .settingDialog {
  text-align: center;
}

.test1 .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test1 .logic-form .el-input--suffix .el-input__inner {
  width: 520px;
}

.test1 .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test1 .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
